.container {
    display: flex;
    flex-grow: 1;
    margin-right: 16px;
    align-items: stretch;
    justify-content: stretch;
    max-height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
}

.fdo {
    flex-grow: 1;
    overflow: auto;
}