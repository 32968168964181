.body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 116px;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.message {
    flex-grow: 1;
    line-height: 1.2;
}

.buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.buttons *:not(:last-child) {
    margin-bottom: 8px;
}

.message :first-child {
    padding-top: 0;
}