.list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 6px 0;
}

.listItem a, .listItem button {
    margin-left: 12px;
}