.toolbar {
    display: flex;
    flex-direction: row;
    box-shadow: inset 0 -1px #0a0a0a,inset 0 -2px grey;
    padding: 3px 3px 6px 3px;
}

.secondary {
    height: 32px;
    align-items: center;
}