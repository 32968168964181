.container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.details {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.section {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.section > :not(:first-child) {
    margin-top: 4px;
    padding-left: 8px;
}

.list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 6px 0;
}

.listItem a, .listItem button {
    margin-left: 12px;
}

.actions {
    background-color: #e0e0e0;
    display: flex;
    flex-direction: column;
}

.actions button {
    cursor: pointer;
    margin-bottom: 4px;
    width: 96px;
}