.form {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2px;
}

.form * {
    margin-right: 12px;
}

.form *:last-child {
    margin-right: 0;
}