.container {
    display: flex;
    flex-direction: row;
}

.container img {
    margin-right: 12px;
    width: 32px;
    height: 32px;
}

.message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
}

.message a {
    align-self: flex-end;
}

.btn {
    background: silver;
    box-shadow: inset -1px -1px #0a0a0a,inset 1px 1px #fff,inset -2px -2px grey,inset 2px 2px #dfdfdf;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    height: 23px;
    width: 75px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    user-select: none;
}

.btn:active {
    box-shadow: inset -1px -1px #fff,inset 1px 1px #0a0a0a,inset -2px -2px #dfdfdf,inset 2px 2px grey;
    padding: 2px 11px 0 13px;
}

.btn:focus {
    outline: 1px dotted #000;
    outline-offset: -4px;
}