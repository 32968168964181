.container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.banner {
    background-color: lightyellow;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
    margin-bottom: 2px;
    border-bottom: solid 1px black;
}

.banner img {
    margin-right: 4px;
}

.results {
    background-color: white;
    flex-grow: 1;
    padding: 8px;
}

.results > * {
    margin-bottom: 12px;
}