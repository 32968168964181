.body {
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.icon {
    height: 32px;
    width: 32px;
    margin-right: 16px;
}

.icon img {
    height: 32px;
    width: 32px;
}

.message {
    line-height: 1.2;
}

.message :first-child {
    margin-top: 4px;
    padding-top: 0;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 12px;
}

.buttons *:not(:last-child) {
    margin-right: 8px;
}