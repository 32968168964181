.centerMessage {
    margin: auto;
    color: #303030;
}

.item {
    background-color: white;
    flex-grow: 1;
    padding: 8px;
    display: flex;
    flex-direction: row;
}

.container {
    display: flex;
    flex-grow: 1;
    margin-right: 16px;
    align-items: stretch;
    justify-content: stretch;
}

.fdo {
    flex-grow: 1;
    overflow: auto;
}

.actions {
    background-color: #e0e0e0;
    display: flex;
    flex-direction: column;
}

.actions button {
    cursor: pointer;
    margin-bottom: 4px;
    width: 96px;
}