.container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.centerMessage {
    margin: auto;
    color: #303030;
}

.results {
    background-color: white;
    flex-grow: 1;
    padding: 8px;
}