.container {
    display: flex;
    flex-grow: 1;
    padding: 12px;
    background-color: #6495ED;
}

.window {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.titleBar {
    justify-content: flex-start;
}

.titleBarText {
    margin-left: 4px;
    flex-grow: 1;
}

.windowBody {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 1px 0 0 0;
    padding: 0;
    overflow: hidden;
}

.pageContainer {
    flex-grow: 1;
    background-color: white;
    background-repeat: repeat;
    overflow: auto;
    display: flex;
    overflow: hidden;
}

.timerStatus {
    flex-grow: unset;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px;
}

.timerStatus img {
    margin-right: 4px;
}