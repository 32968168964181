.backdrop {
    background-color: transparent;
    cursor: not-allowed;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 30;
}

.backdrop.semiTransparent {
    background-color: rgba(255, 255, 255, 0.35);
}

.contents {
    z-index: 40;
}