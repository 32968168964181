.container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.inner {
    background-color: #a0a0a0;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    position: relative;
}

.nav {
    position: absolute;
    left: 4px;
    top: 4px;
    bottom: 4px;
    width: 200px;
    overflow-y: scroll;
}

.outlet {
    position: absolute;
    top: 4px;
    left: 224px;
    bottom: 4px;
    right: 4px;
}