.meter {
    height: 24px;
    /* Can be anything */
    position: relative;
    box-shadow: inset -1px -1px #ffffff,
        inset 1px 1px #808080, inset -2px -2px #dfdfdf,
        inset 2px 2px #0a0a0a;
    padding: 3px 3px 3px 3px;
    border: none;
    background-color: #ffffff;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
}

.meter>div {
    background-image: url("../assets/progress-block.png");
    background-repeat: round;
    width: 30%;
    height: 20px;
    z-index: 1;
}