.btn {
    background: silver;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    user-select: none;
    color: black;
    text-decoration: none;
    min-width: unset;
    flex-direction: column;
    margin-right: 4px;
    width: 54px;
    height: 54px;
    cursor: pointer;
    box-shadow: unset;
}
.btn.disabled {
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: 0.35;
    box-shadow: unset;
}
.btn.disabled:hover {
    box-shadow: unset;
}
.btn.disabled:active {
    background: unset;
    box-shadow: unset;
    padding: 4px;
}

[data-secondary='yes'] > * > .btn img,
[data-secondary='yes'] > .btn img {
    margin-right: 4px;
}

[data-secondary='yes'] > * > .btn,
[data-secondary='yes'] > .btn {
    flex-direction: row;
    width: unset;
    height: unset;
}

:not([data-secondary='yes']) > .btn:last-child {
    margin-left: auto;
    margin-right: 0;
}

.btn:hover {
    box-shadow: inset -1px -1px #0a0a0a,inset 1px 1px #fff,inset -2px -2px grey,inset 2px 2px #dfdfdf;
}

.btn:not(:disabled):active {
    background: #d0d0d0;
    box-shadow: inset -1px -1px #fff,inset 1px 1px #0a0a0a,inset -2px -2px #dfdfdf,inset 2px 2px grey;
    padding: 5px 3px 3px 5px;
}

.btn:focus {
    outline: none;
}
