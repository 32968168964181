.container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.outlet {
    flex-grow: 1;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    background-color: #808080;
}

