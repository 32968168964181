fieldset legend {
    background-color: unset;
}

button.link {
    background-color: transparent;
    box-shadow: unset;
    color: #00f;
    cursor: pointer;
    text-decoration: underline;
    min-height: unset;
    min-width: unset;
    padding: unset;
}

button.link:focus {
    outline: 1px dotted #00f;
    outline-offset: unset;
    padding: unset;
}

button.link:active {
    color: #808;
    box-shadow: unset;
    padding: unset;
}

h6 {
    font-family: Tahoma, sans-serif;
    font-size: 16px;
    margin: 0;
}