.windowBody {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 624px;
    height: 444px;
    margin: 0;
}

.windowBody hr {
    width: 100%;
    margin-top: 1px;
    margin-bottom: 12px;
}

.contents {
    margin-top: 1px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}

.banner {
    flex-shrink: 0;
    width: 150px;
    background-size: cover;
}

.wizardInner {
    padding: 12px;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

.buttons button:last-child {
    margin-left: 12px;
}